import { fastbookingSelector as e } from 'barcelo-components/structure/header-detail/js/c-header-detail';
bcl.c.resortMap = {
    props: {
        mapContainerDivClass: ".interactive-map__background",
        imageMapClass: ".cmp-image__image",
        ctaChildClass: ".interactive-map__cta-JS",
        resortDotClass: "resort-map-dot-JS",
        resortParentDotClass: 'resort-parent-dot-JS',
        resortCardFlapClass: "c-map-resort__flap-JS",
        resortCardClass: 'card-resort-JS',
        resortListCategoriesClass: 'list-categories-JS',
        resortListCategoriesContainerClass: 'list-categories-container-JS',
        resortListCommonsCategoriesClass: 'list-categories-container-commons-JS',
        resortListSpecificCategoriesContainerMiniCardWrapperClass: 'mini-cards-wrapper-JS',
        resortCategoryItemClass: 'category-item-JS',
        resortListCategoriesHeadingClass: 'heading-results-JS',
        resortListActiveCategoriesHeadingClass: 'heading-results-active-JS',
        resortCategoryFilterClass: 'category-filter-JS',
        resortCategoryFilterButtonClass: 'category-filter-button-JS',
        resortContainerWrapClass: 'resort-container-wrap-JS',
        resortContainerWrapperScrollClass: 'wrapper-container-scroll-JS',
        activeClass: 'mod--active',
        disabledClass: 'mod--disabled',
        showMapMobileClass: 'show-map-JS',
        tabsMapMobileClass: 'map-resort-mobile-tabs-JS',
        tabMapButtonMobileClass: 'tab-map-button-JS',
        tabListButtonMobileClass: 'tab-list-button-JS',
        modalPreMapClass: 'modal-premap-JS',
        modalContentClass: 'modal-content-JS',
        modalCloseButtonClass: 'tingle-modal__close-JS',
        hiddenClass: 'hidden',
        fadeOutClass: 'fadeOut',
        resortMapBackgroundClass: "map-background-JS",
        resortMapWrapperClass: "resort-map-wrapper-JS",
        zoomButtonsContainerClass: "zoom-control-JS",
        zoomInButtonClass: "zoom-in-JS",
        zoomOutButtonClass: "zoom-out-JS",
        selectOptionHotelsClass: "select-hotels__option-JS",
        centerMapPointReferenceClass: "center-dot-JS",
        $backgroundImageElement: null,
        imgDefaultValues: {
            width: 100,
            height: 100
        },
        mapWrapperDefaultValues: {
            width: 100,
            height: 100
        },
        offset: [
            0,
            0
        ],
        isDown: !1,
        $resortListCategoriesContainer: null,
        categoryType: null,
        parentFb: null,
        $resortMapContainer: null,
        currentSelectedDotIndex: null,
        imageParentElement: null,
        $tabsMobileContainer: null,
        naturalHeight: null,
        naturalWidth: null,
        aspect: null,
        scaling: !1,
        prevDiff: -1,
        touches: []
    },
    init: function(e) {
        let t = e || document, r = t.querySelector(`.${bcl.c.resortMap.props.resortContainerWrapClass}`), s = t.querySelector("." + bcl.c.resortMap.props.modalPreMapClass);
        bcl.c.resortMap.initFbAndHeader(t), bcl.c.resortMap.props.$tabsMobileContainer = t.querySelector("." + bcl.c.resortMap.props.tabsMapMobileClass), bcl.c.resortMap.handlerResortDots(t), bcl.c.resortMap.handlerResortCardFlaps(t), bcl.c.resortMap.handlerMobileMapButtons(t), bcl.c.resortMap.handlerZoomOfMap(t, r), bcl.c.resortMap.hideFbWhenInteractWithMap(r, t), bcl.c.resortMap.initModal(s), bcl.c.resortMap.initTabsMobileButtons(bcl.c.resortMap.props.$tabsMobileContainer), bcl.c.resortMap.addClickToSelectOption(t), bcl.c.resortMap.centerMapFirstTime(), bcl.u.mobile.isMobile() || bcl.c.resortMap.touchMovementInDesktop(t);
    },
    hideFbWhenInteractWithMap: function(e, t) {
        e?.addEventListener('click', ()=>{
            bcl.c.resortMap.initFbAndHeader(t);
        });
    },
    checkMinimumSizeOfElement: function(e, t) {
        return `${bcl.c.resortMap.props.mapWrapperDefaultValues.width}px` === e.style.width || !e.style.width && `${bcl.c.resortMap.props.mapWrapperDefaultValues.height}px` === e.style.height || !e.style.height && `${bcl.c.resortMap.props.imgDefaultValues.height}px` === t.style.height || !t.style.height && `${bcl.c.resortMap.props.imgDefaultValues.width}px` === t.style.width || !t.style.width;
    },
    increasedZoom: function(e, t, r, s, o) {
        let a = e.clientWidth, l = t.clientWidth, c = t.clientHeight, p = e.clientHeight, n = r.clientHeight, i = r.clientWidth;
        bcl.c.resortMap.checkMinimumSizeOfElement(t, e), !bcl.c.resortMap.checkMinimumSizeOfElement(t, e) && bcl.u.containsClass(s, "mod--disabled") && bcl.u.removeClass(s, "mod--disabled"), e.style.width = `${a + o}px`, e.style.height = `${p + o}px`, r.style.height = `${n + o}px`, r.style.width = `${i + o}px`, t.style.width = `${l + o}px`, t.style.height = `${c + o}px`;
    },
    initZoomButtonsEvent: function(e, t, r, s, o) {
        e.addEventListener('click', ()=>{
            bcl.c.resortMap.increasedZoom(t, r, s, o, 100);
        }), o.addEventListener('click', (e)=>{
            bcl.c.resortMap.decreasedZoom(t, r, s, e.target, 100);
        });
    },
    decreasedZoom: function(e, t, r, s, o) {
        let a = e.clientWidth, l = t.clientWidth, c = t.clientHeight, p = e.clientHeight, n = r.clientHeight, i = r.clientWidth;
        if (bcl.c.resortMap.checkMinimumSizeOfElement(t, e)) {
            bcl.u.containsClass(s, "mod--disabled") || bcl.u.addClass(s, "mod--disabled");
            return;
        }
        e.style.width = bcl.c.resortMap.props.imgDefaultValues.width < a ? `${a - o}px` : `${bcl.c.resortMap.props.imgDefaultValues.width}px`, e.style.height = bcl.c.resortMap.props.imgDefaultValues.height < p ? `${p - o}px` : `${bcl.c.resortMap.props.imgDefaultValues.height}px`, r.style.height = `${n - o}px`, r.style.width = `${i - o}px`, t.style.width = bcl.c.resortMap.props.mapWrapperDefaultValues.width < l ? `${l - o}px` : `${bcl.c.resortMap.props.mapWrapperDefaultValues.width}px`, t.style.height = bcl.c.resortMap.props.mapWrapperDefaultValues.height < c ? `${c - o}px` : `${bcl.c.resortMap.props.mapWrapperDefaultValues.height}px`;
    },
    controlDimensionImageMap: function(e, t, r, s) {
        e.style.width = `${s}px`, e.style.height = `${s}px`, t.style.width = `${s}px`, t.style.height = `${s}px`, r.style.width = `${s}px`, r.style.height = `${s}px`, r.style.minWidth = `${s}px`;
    },
    touchMovementInDesktop: function(e) {
        let t = e.querySelector(`.${bcl.c.resortMap.props.resortContainerWrapperScrollClass}`);
        t?.addEventListener('mousedown', function(e) {
            bcl.c.resortMap.props.isDown = !0, bcl.c.resortMap.props.offset = [
                t.scrollLeft + e.clientX,
                t.scrollTop + e.clientY
            ];
        }, !0), document.addEventListener('mouseup', function() {
            bcl.c.resortMap.props.isDown = !1;
        }, !0), document.addEventListener('mousemove', function(e) {
            e.preventDefault(), bcl.c.resortMap.props.isDown && (t.scrollTop = bcl.c.resortMap.props.offset[1] - e.clientY, t.scrollLeft = bcl.c.resortMap.props.offset[0] - e.clientX);
        }, !0);
    },
    customTouchEvent: function(e, t, r, s) {
        e.addEventListener('touchstart', function(e) {
            2 === e.touches.length && (bcl.c.resortMap.props.scaling = !0, bcl.c.resortMap.props.touches = [
                e.touches[0],
                e.touches[1]
            ]);
        }, !1), e.addEventListener('touchmove', function(o) {
            if (console.log("on touch end "), bcl.c.resortMap.props.scaling) {
                console.log("touches: " + o.touches.length), console.log("touch 1: " + o.touches[0]), console.log("touch 2: " + o.touches[1]), console.log("props touch 2: " + bcl.c.resortMap.props.touches[1]);
                var a = Math.hypot(bcl.c.resortMap.props.touches[0].pageX - bcl.c.resortMap.props.touches[1].pageX, bcl.c.resortMap.props.touches[0].pageY - bcl.c.resortMap.props.touches[1].pageY);
                let l = Math.abs(bcl.c.resortMap.props.touches[0].clientX - bcl.c.resortMap.props.touches[1].clientX);
                bcl.c.resortMap.props.prevDiff > 0 && (console.log("dist: ", a), o.preventDefault(), o.stopPropagation(), l > bcl.c.resortMap.props.prevDiff ? (console.log("distance is", a, ", enter of if"), bcl.c.resortMap.increasedZoom(t, e, r, s, 1)) : l < bcl.c.resortMap.props.prevDiff && (console.log("distance is " + a + ", enter of else"), bcl.c.resortMap.decreasedZoom(t, e, r, s, 1)), bcl.c.resortMap.props.scaling = !1), bcl.c.resortMap.props.prevDiff = l;
            }
        }, !1);
    },
    handlerZoomOfMap: (e)=>{
        let t = e.querySelector(`.${bcl.c.resortMap.props.resortMapBackgroundClass}`), r = e.querySelector(`.${bcl.c.resortMap.props.resortContainerWrapClass}`), s = t?.querySelector("img"), o = s?.parentNode, a = e.querySelector(`.${bcl.c.resortMap.props.zoomButtonsContainerClass}`), l = a?.querySelector(`.${bcl.c.resortMap.props.zoomInButtonClass}`), c = a?.querySelector(`.${bcl.c.resortMap.props.zoomOutButtonClass}`);
        s && (bcl.c.resortMap.props.$backgroundImageElement = s, bcl.u.mobile.isMobile() || bcl.c.resortMap.controlDimensionImageMap(s, o, r, Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)), bcl.u.mobile.isMobile() && bcl.c.resortMap.controlDimensionImageMap(s, o, r, Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)), bcl.c.resortMap.props.imgDefaultValues.height = s.clientHeight, bcl.c.resortMap.props.imgDefaultValues.width = s.clientWidth, bcl.c.resortMap.props.mapWrapperDefaultValues.width = r.clientWidth, bcl.c.resortMap.props.mapWrapperDefaultValues.height = r.clientHeight, bcl.c.resortMap.initZoomButtonsEvent(l, s, r, o, c));
    },
    doMoveToCenter: (e)=>{
        e.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
        });
    },
    initFbAndHeader: function(t) {
        let r = t.querySelector("." + bcl.c.resortMap.props.resortContainerWrapClass), s = t.querySelector("." + e) ? t.querySelector("." + e) : t.querySelector(".c-fastbooking");
        r && s && bcl.u.commonsResortsUtils.hideFB(r, s), r?.dataset?.hidefastbooking == "true" ? r && bcl.u.commonsResortsUtils.embedHeader() : r && bcl.u.commonsResortsUtils.embedHeader("mod--fastbooking-show");
    },
    handlerResortDots: function(e) {
        let t = e.querySelectorAll("." + bcl.c.resortMap.props.resortDotClass);
        t && t.length > 0 && bcl.u.forEach(t, function(t) {
            bcl.c.resortMap.initDotEvents(t, e);
        });
    },
    handlerResortCardFlaps: function(e) {
        let t = e.querySelectorAll("." + bcl.c.resortMap.props.resortCardFlapClass);
        t && t.length > 0 && bcl.u.forEach(t, function(e) {
            bcl.c.resortMap.initCardFlap(e);
        });
    },
    handlerMobileMapButtons: function(e) {
        let t = e.querySelector("." + bcl.c.resortMap.props.showMapMobileClass);
        t && t.addEventListener('click', ()=>{
            let t = [
                ...e.querySelectorAll('.' + bcl.c.resortMap.props.resortCardClass + ", ." + bcl.c.resortMap.props.resortListCategoriesClass)
            ].filter((e)=>bcl.u.containsClass(e, bcl.c.resortMap.props.activeClass));
            bcl.u.removeClass(t, bcl.c.resortMap.props.activeClass);
        });
    },
    initModal: function(e) {
        if (!e) return;
        let t = e.querySelector("." + bcl.c.resortMap.props.modalContentClass), r = e.querySelector("." + bcl.c.resortMap.props.modalCloseButtonClass);
        t.addEventListener('click', ()=>{
            e.remove();
        }), r.addEventListener('click', ()=>{
            e.remove();
        });
    },
    toggleRelatedElements: function(e) {
        bcl.u.forEach(e, (e)=>{
            bcl.u.toggleClass(e, bcl.c.resortMap.props.fadeOutClass);
        });
    },
    resetAllButtons: function(e) {
        bcl.u.forEach(e, (e)=>{
            bcl.u.containsClass(e, bcl.c.resortMap.props.hiddenClass) || bcl.u.addClass(e, bcl.c.resortMap.props.hiddenClass), bcl.u.containsClass(e, bcl.c.resortMap.props.disabledClass) && bcl.u.removeClass(e, bcl.c.resortMap.props.disabledClass);
        });
    },
    filterInListAndDots: function(e) {
        let t = bcl.u.containsClass(e.target, bcl.c.resortMap.props.resortCategoryFilterButtonClass) ? e.target : bcl.u.closest(e.target, "." + bcl.c.resortMap.props.resortCategoryFilterButtonClass), r = t.dataset.categoryType, s = bcl.c.resortMap.props.currentElementChildDots;
        bcl.u.toggleClass(t, bcl.c.resortMap.props.disabledClass);
        let o = bcl.c.resortMap.props.$resortListCategoriesContainer.querySelectorAll(`[data-category-type=${r}]`), a = [
            ...s
        ];
        bcl.c.resortMap.toggleRelatedElements(o), bcl.c.resortMap.toggleRelatedElements(a.filter((e)=>e.dataset.categoryType === r));
        let l = bcl.c.resortMap.props.$resortMapContainer?.querySelector("." + bcl.c.resortMap.props.resortListCategoriesContainerClass), c = l?.querySelector("." + bcl.c.resortMap.props.resortListSpecificCategoriesContainerMiniCardWrapperClass), p = c?.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryItemClass + ":not(." + bcl.c.resortMap.props.fadeOutClass + ")"), n = c?.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryItemClass), i = l?.querySelector("." + bcl.c.resortMap.props.resortListCategoriesHeadingClass), d = l?.querySelector("." + bcl.c.resortMap.props.resortListActiveCategoriesHeadingClass);
        bcl.c.resortMap.changeNumberElements(i, d, p, n);
    },
    initFilterButtons: function(e, { categoryTypesWithOutDuplicates: t, currentElementChildDots: r, filterContainer: s }) {
        bcl.c.resortMap.resetAllButtons(e), bcl.u.forEach(t, (e)=>{
            let t = s.querySelector(`[data-category-type=${e}]`);
            t && (bcl.u.containsClass(t, bcl.c.resortMap.props.hiddenClass) && bcl.u.removeClass(t, bcl.c.resortMap.props.hiddenClass), bcl.c.resortMap.props.currentElementChildDots = r, t.removeEventListener('click', bcl.c.resortMap.filterInListAndDots), t.addEventListener('click', bcl.c.resortMap.filterInListAndDots));
        });
    },
    initFilters: function(e, t, r) {
        bcl.u.forEach(t, (e)=>{
            r.push(e.dataset.categoryType);
        });
        let s = e.querySelector("." + bcl.c.resortMap.props.resortCategoryFilterClass), o = [
            ...new Set(r)
        ], a = s?.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryFilterButtonClass);
        a && bcl.c.resortMap.initFilterButtons(a, {
            categoryTypesWithOutDuplicates: o,
            currentElementChildDots: t,
            filterContainer: s
        }), bcl.u.containsClass(s, bcl.c.resortMap.props.hiddenClass) && bcl.u.removeClass(s, bcl.c.resortMap.props.hiddenClass);
    },
    initTabsMobileButtons: function(e) {
        e && e.querySelector("." + bcl.c.resortMap.props.tabListButtonMobileClass).addEventListener('click', ()=>{
            bcl.u.addClass(bcl.c.resortMap.props.$resortListCategoriesContainer, bcl.c.resortMap.props.activeClass);
        });
    },
    initDotEvents: function(e, t) {
        e.addEventListener('click', function(e) {
            let r = bcl.u.containsClass(e.target, bcl.c.resortMap.props.resortDotClass) ? e.target : bcl.u.closest(e.target, "." + bcl.c.resortMap.props.resortDotClass), s = bcl.u.closest(r, "." + bcl.c.resortMap.props.resortContainerWrapClass), o = r.dataset.hotelPath ? r.dataset.hotelPath : r.dataset.categoryPath;
            new Promise((e)=>{
                bcl.c.resortMap.requestResortCard(r, o, "card-map", e);
            }).then(()=>{
                if (r.dataset.parentIndex && '999' === r.dataset.parentIndex) {
                    let e = r.dataset.index, a = [
                        ...s.querySelectorAll(".dot-" + e + ":not(." + bcl.c.resortMap.props.resortParentDotClass + ")"),
                        ...s.querySelectorAll(".common-point-JS")
                    ];
                    bcl.c.resortMap.props.currentSelectedDotIndex = e, bcl.c.resortMap.toggleChildDots(r, s, a), bcl.c.resortMap.initFilters(t, a, []), bcl.c.resortMap.requestResortCard(r, o, "list-categories"), bcl.u.mobile.isMobile() && bcl.u.removeClass(bcl.c.resortMap.props.$tabsMobileContainer, bcl.c.resortMap.props.hiddenClass);
                }
            }), bcl.c.resortMap.doMoveToCenter(r);
        });
    },
    initCardFlap: function(e) {
        e.addEventListener('click', function(e) {
            let t = bcl.u.closest(e.target, '.' + bcl.c.resortMap.props.resortCardClass + ", ." + bcl.c.resortMap.props.resortListCategoriesClass);
            t && bcl.u.toggleClass(t, bcl.c.resortMap.props.activeClass);
        });
    },
    requestResortCard: function(e, t, r, s) {
        '999' === e.dataset.parentIndex && (t = bcl.u.addedSelectorInUrl(t, r), "card-map" === r && bcl.ajax.getRequest(null, t, bcl.c.resortMap.changeHtmlCard, {
            $item: e,
            selector: r,
            promiseResolver: s
        }, !0), "card-map" !== r && bcl.c.resortMap.changeHtmlCard(null, {
            $item: e,
            selector: r,
            promiseResolver: s
        })), '999' !== e.dataset.parentIndex && bcl.c.resortMap.changeHtmlCard(null, {
            $item: e,
            selector: r,
            promiseResolver: s
        });
    },
    activeCards: function(e) {
        bcl.u.addClass(e, bcl.c.resortMap.props.activeClass);
    },
    replaceContent: function(e, { $container: t, activateFlag: r, $item: s, isService: o }) {
        let a = document.createElement('div'), l = s ? t.querySelector(".child-to-replace-JS." + bcl.c.resortMap.props.resortListCategoriesContainerClass + ":not(" + bcl.c.resortMap.props.hiddenClass + ")") : t.querySelector(".child-to-replace-JS"), c = t.querySelector(".service-card-container-JS"), p = c?.querySelector("article"), n = p?.cloneNode(!0);
        if (n && o) {
            let t = JSON.parse(e.response), r = n.querySelector(".container-image-JS"), s = r?.querySelector("img"), o = n.querySelector(".c-card-content-title-JS"), a = n.querySelector(".c-card-content-description-JS"), l = document.createElement('div');
            r.dataset.img = t.preview, s.src = t.preview, o.innerHTML = t.title, t.longDescription && (l.innerHTML = t.longDescription, a.innerHTML = l.innerText), bcl.u.addClass(n, "child-to-replace-JS");
        }
        if (a.innerHTML = o ? n.outerHTML : e?.response, t.removeChild(l), s || t.appendChild(a.children[0]), s) {
            let e = t.querySelector(".list-categories-container-specific-hotel-JS[data-hotel-path='" + s.dataset.hotelPath + "']"), r = e?.cloneNode(!0);
            r && (bcl.u.removeClass(r, "hidden"), bcl.u.addClass(r, bcl.c.resortMap.props.resortListCategoriesContainerClass), t.appendChild(r));
        }
        return bcl.u.removeClass(t, bcl.c.resortMap.props.hiddenClass), r && bcl.c.resortMap.activeCards(t), !0;
    },
    syncListCategoryItems: function(e, t) {
        let r = t?.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryItemClass);
        bcl.u.forEach(r, (e)=>{
            bcl.c.resortMap.props.currentElementChildDots && 0 === [
                ...bcl.c.resortMap.props.currentElementChildDots
            ].filter((t)=>t.dataset.categoryPath === e.dataset.categoryPath).length ? e.remove() : (e.removeEventListener('click', bcl.c.resortMap.categoryMiniCardEvent), e.addEventListener('click', bcl.c.resortMap.categoryMiniCardEvent));
        }), e && bcl.u.forEach(e, (e)=>{
            let t = bcl.c.resortMap.props.$resortMapContainer?.querySelector("." + bcl.c.resortMap.props.resortListCategoriesContainerClass), r = t?.querySelector("." + bcl.c.resortMap.props.resortListSpecificCategoriesContainerMiniCardWrapperClass), s = e.querySelector("." + bcl.c.resortMap.props.resortListCategoriesHeadingClass), o = e?.querySelector("." + bcl.c.resortMap.props.resortListActiveCategoriesHeadingClass), a = r.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryItemClass);
            bcl.c.resortMap.changeNumberElements(s, o, a, a);
        });
    },
    categoryMiniCardEvent: (e)=>{
        let t = bcl.u.containsClass(e.target, bcl.c.resortMap.props.resortCategoryItemClass) ? e.target : bcl.u.closest(e.target, '.' + bcl.c.resortMap.props.resortCategoryItemClass), r = t?.dataset.categoryPath, s = t?.dataset.commonPoint, o = document.querySelector(`.dot-${s || bcl.c.resortMap.props.currentSelectedDotIndex}.${bcl.c.resortMap.props.resortDotClass}[data-category-path='${r}']`), a = t.closest("." + bcl.c.resortMap.props.resortListCategoriesClass);
        a && bcl.u.mobile.isMobile() && bcl.u.removeClass(a, bcl.c.resortMap.props.activeClass), o && o.click();
    },
    changeHtmlCard: function(e, { $item: t, selector: r, promiseResolver: s }) {
        let o = bcl.u.closest(t, ".map-resort"), a = o.querySelector("." + bcl.c.resortMap.props.resortCardClass), l = o.querySelector("." + bcl.c.resortMap.props.resortListCategoriesClass), c = l?.querySelector("." + bcl.c.resortMap.props.resortListCommonsCategoriesClass);
        bcl.c.resortMap.props.$resortMapContainer = o, bcl.c.resortMap.props.$resortListCategoriesContainer = l;
        let p = {
            service: t?.dataset.categoryPath?.replace(".html", "")
        }, n = a.querySelector("." + "service-card-container-JS")?.dataset.url, i = n?.replace(".html", ".bcldata.html");
        if (a && "card-map" === r && ('999' === t.dataset.parentIndex && bcl.c.resortMap.replaceContent(e, {
            $container: a,
            activateFlag: !0
        }) && bcl.c.fastbooking.initBookings(a), '999' !== t.dataset.parentIndex && bcl.ajax.postRequest({
            data: p,
            url: i,
            callback: bcl.c.resortMap.replaceContent,
            itemCallback: {
                $container: a,
                activateFlag: !0,
                isService: !0
            }
        })), l && '999' === t.dataset.parentIndex && "card-map" !== r) {
            bcl.c.resortMap.replaceContent(e, {
                $container: l,
                activateFlag: !bcl.u.mobile.isMobile(),
                $item: t
            });
            let r = o.querySelector("." + bcl.c.resortMap.props.resortListCategoriesContainerClass), s = r?.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryItemClass), a = bcl.c.resortMap.props.$resortListCategoriesContainer?.querySelectorAll("." + "heading-results-container-JS"), p = r?.querySelector("." + bcl.c.resortMap.props.resortListSpecificCategoriesContainerMiniCardWrapperClass), n = c?.querySelectorAll("." + bcl.c.resortMap.props.resortCategoryItemClass);
            bcl.u.forEach(n, (e)=>{
                let t = e?.cloneNode(!0);
                p && s && p.insertBefore(t, s[s.length]);
            }), bcl.c.resortMap.syncListCategoryItems(a, p);
        }
        s && s();
    },
    changeNumberElements: function(e, t, r, s) {
        e && t && r && (t.innerHTML = r.length + " " + t.dataset.resultLabel, e.innerHTML = s.length + " " + e.dataset.resultLabel);
    },
    toggleChildDots: function(e, t, r) {
        let s = t.querySelectorAll("." + bcl.c.resortMap.props.resortDotClass + ":not(." + bcl.c.resortMap.props.resortParentDotClass + ")");
        return s.length > 0 && bcl.u.forEach(s, function(e) {
            bcl.u.containsClass(e, bcl.c.resortMap.props.hiddenClass) || bcl.u.addClass(e, bcl.c.resortMap.props.hiddenClass);
        }), r.length > 0 && bcl.u.forEach(r, function(e) {
            bcl.u.containsClass(e, bcl.c.resortMap.props.hiddenClass) && bcl.u.removeClass(e, bcl.c.resortMap.props.hiddenClass);
        }), !0;
    },
    addClickToSelectOption: function(e) {
        let t = e.querySelectorAll('.' + bcl.c.resortMap.props.selectOptionHotelsClass);
        t && bcl.u.forEach(t, function(e) {
            e.addEventListener('click', bcl.c.resortMap.findAndClickHotelPointInMap);
        });
    },
    centerMapFirstTime: function() {
        bcl.c.resortMap.props.$backgroundImageElement && setTimeout(bcl.c.resortMap.centerMap, 300);
    },
    centerMap: function() {
        let e = document.querySelector('.' + bcl.c.resortMap.props.centerMapPointReferenceClass);
        e && bcl.c.resortMap.doMoveToCenter(e);
    },
    findAndClickHotelPointInMap: function(e) {
        let t = e.target.dataset.index;
        if ('999' === t) {
            bcl.c.resortMap.centerMap();
            let e = document.querySelector('.' + bcl.c.resortMap.props.resortCardClass), t = document.querySelector('.' + bcl.c.resortMap.props.resortListCategoriesClass);
            e && bcl.u.containsClass(e, "mod--active") && bcl.u.removeClass(e, "mod--active"), t && bcl.u.containsClass(t, "mod--active") && bcl.u.removeClass(t, "mod--active");
        }
        if (t) {
            let e = document.querySelectorAll("." + bcl.c.resortMap.props.resortParentDotClass);
            e && e.length > 0 && bcl.u.forEach(e, function(e) {
                e.dataset.index === t && e.click();
            });
        }
    }
}, bcl.u.docReady(bcl.c.resortMap.init);
