bcl.c.carouselList = {
    props: {
        cssSelector: "cmp-bhg-carousel-nested",
        cssSelectorSwiper: ".swiper-wrapper-nested[data-items-load]",
        cssSelectorCarousel: ".cmp-br-carousel",
        nameAttr: "data-items-load",
        readMore: ".read_more-JS",
        readLess: ".read_less-JS",
        shortText: ".short_text-JS",
        completeText: ".complete_text-JS",
        hidden: "hidden",
        config: {
            slidesPerView: "auto",
            grabCursor: !0,
            centeredSlides: !0,
            watchOverflow: !0,
            observer: !0,
            observeParents: !0,
            navigation: {
                nextEl: ".swiper-button-next-nested",
                prevEl: ".swiper-button-prev-nested"
            },
            pagination: {
                el: ".swiper-pagination-nested",
                type: "fraction"
            },
            keyboard: !0
        }
    },
    profiledProps: {
        carouselListSelector: ".carousel-list-js",
        carouselListItemSelector: ".carousel-list-services-js",
        carouselListParentSelector: ".carousel-list-parent-js",
        localStorageProp: "resortProfiled",
        hidden: "hidden",
        type: {
            carouselList: "carousel-category-list-l",
            carouselListC: "carousel-category-list-c",
            carouselListHotel: "carousel-hotel-list",
            carousel: "carousel"
        }
    },
    init: function(e) {
        let s = e || document, l = s.querySelectorAll(bcl.c.carouselList.profiledProps.carouselListSelector);
        bcl.u.forEach(l, bcl.c.carouselList.carouselHandler), bcl.u.swiper.init(s, bcl.c.carouselList.props);
    },
    carouselHandler: function(e) {
        if (bcl.c.carouselList.checkProfiled(e), e.dataset.carouseltype === bcl.c.carouselList.profiledProps.type.carouselList) {
            let s = e.querySelectorAll(bcl.c.carouselList.profiledProps.carouselListItemSelector);
            s && bcl.u.forEach(s, bcl.c.carouselList.initNodeReadMore);
        }
    },
    checkProfiled: function(e) {
        if ("true" === e.dataset.profiled) {
            let s = bcl.c.carouselList.loadData(), l = e.dataset.carouseltype;
            s && s.profiled && (l === bcl.c.carouselList.profiledProps.type.carouselList || l === bcl.c.carouselList.profiledProps.type.carouselListC || l === bcl.c.carouselList.profiledProps.type.carouselListHotel) ? (bcl.c.carouselList.showCarousel(e), bcl.c.carouselList.getProfile(e, s, l)) : s && s.profiled && l === bcl.c.carouselList.profiledProps.type.carousel && bcl.c.carouselList.getProfile(e, s, l);
        }
    },
    loadData: function() {
        let e = localStorage.getItem(bcl.c.carouselList.profiledProps.localStorageProp);
        return null != e && JSON.parse(e);
    },
    showCarousel: function(e) {
        bcl.u.removeClass(e, bcl.c.carouselList.profiledProps.hidden);
    },
    getProfile: function(e, s, l) {
        let r = e.querySelectorAll(bcl.c.carouselList.profiledProps.carouselListItemSelector), c = s.preferences.categories, o = e.dataset.carouseltype === bcl.c.carouselList.profiledProps.type.carouselList ? e : e.querySelector(bcl.c.carouselList.profiledProps.carouselListParentSelector);
        bcl.c.carouselList.excludeChild(c, r, o, l);
    },
    excludeChild: function(e, s, l, r) {
        bcl.u.forEach(s, function(s) {
            r === bcl.c.carouselList.profiledProps.type.carouselList || r === bcl.c.carouselList.profiledProps.type.carouselListC ? e.includes(s.dataset.service) || l.removeChild(s) : r === bcl.c.carouselList.profiledProps.type.carousel && e.includes(s.dataset.service) && l.removeChild(s);
        });
    },
    initNodeReadMore: function(e) {
        let s = e.querySelector(bcl.c.carouselList.props.readMore);
        s && s.addEventListener("click", bcl.c.carouselList.loadMore);
        let l = e.querySelector(bcl.c.carouselList.props.readLess);
        l && l.addEventListener("click", bcl.c.carouselList.loadLess);
    },
    loadMore: function(e) {
        let s = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.shortText), l = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.readMore), r = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.completeText), c = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.readLess);
        l && bcl.u.addClass(l, bcl.c.carouselList.props.hidden), s && bcl.u.addClass(s, bcl.c.carouselList.props.hidden), c && bcl.u.removeClass(c, bcl.c.carouselList.props.hidden), r && bcl.u.removeClass(r, bcl.c.carouselList.props.hidden);
    },
    loadLess: function(e) {
        let s = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.shortText), l = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.readMore), r = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.completeText), c = e.target?.closest(bcl.c.carouselList.profiledProps.carouselListItemSelector)?.querySelector(bcl.c.carouselList.props.readLess);
        c && bcl.u.addClass(c, bcl.c.carouselList.props.hidden), r && bcl.u.addClass(r, bcl.c.carouselList.props.hidden), l && bcl.u.removeClass(l, bcl.c.carouselList.props.hidden), s && bcl.u.removeClass(s, bcl.c.carouselList.props.hidden);
    }
}, bcl.u.docReady(bcl.c.carouselList.init);
