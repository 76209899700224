bcl.c.headerResorts = {
    props: {
        heroSliderFBClass: ".container-fastbooking-hero-JS",
        openFBButtonClass: ".open-fastbooking-JS"
    },
    init: function(e) {
        let o = (e || document).querySelector(bcl.c.headerResorts.props.heroSliderFBClass);
        if (o && o.dataset.fbHide) {
            let e = document.querySelector(bcl.c.headerResorts.props.openFBButtonClass);
            e && bcl.u.addClass(e, 'hidden');
        }
    }
}, bcl.u.docReady(bcl.c.headerResorts.init, !0);
