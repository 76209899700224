import { cssSelector as e } from 'barcelo-components/structure/heroslider/js/c-heroslider';
bcl.c.herosliderResort = {
    props: {
        cssSelector: "c-heroslider",
        cssScrolldownSelector: "scrolldown-JS",
        $heroSlider: null
    },
    init: function() {
        let r = document.querySelector("." + e);
        if (r) {
            bcl.c.herosliderResort.props.$heroSlider = r;
            let e = r.querySelector("." + bcl.c.herosliderResort.props.cssScrolldownSelector);
            e && bcl.c.herosliderResort.addScrollDownListener(e);
        }
    },
    addScrollDownListener: function(e) {
        e.addEventListener('click', bcl.c.herosliderResort.calculateScroll);
    },
    calculateScroll: function() {
        let e = bcl.c.herosliderResort.props.$heroSlider.scrollHeight;
        e && bcl.u.scrollToSmoothly(e, 500);
    }
}, bcl.c.herosliderResort.init();
