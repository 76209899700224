import { parentSelector as s } from 'barcelo-components/structure/header-detail/js/c-header-detail';
bcl.u.commonsResortsUtils = {
    props: {
        hidden: "hidden"
    },
    embedHeader: function(s) {
        let e = document.querySelector("header"), o = "c-container-collapsed-desktop c-container-collapsed";
        e && !bcl.u.containsClass(e, o.split(" ")[0]) && bcl.u.addClass(e, o.split(" ")[0]), e && !bcl.u.containsClass(e, o.split(" ")[1]) && bcl.u.addClass(e, o.split(" ")[1]), s && e && !bcl.u.containsClass(e, s) && bcl.u.addClass(e, s);
    },
    hideFB: function(e, o) {
        let t = e.dataset.hidefastbooking, l = o.closest(s) ? o.closest(s) : o.closest(".web-inherited-reference");
        l && (t && bcl.u.addClass(l, bcl.u.commonsResortsUtils.props.hidden), bcl.c.fastbooking.commonProps.fastbooking.parentFb = l);
    }
};
