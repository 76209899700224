bcl.u.profilig = {
    props: {
        cssProfilingSelector: ".c-profiled-tag-JS",
        cssCarrouselListSelector: "carousel-list-js",
        localStorageProp: "resortProfiled",
        hidden: "hidden",
        bookButtonWizardBannerSelector: ".booking-button-JS"
    },
    init: function(o) {
        var l = o || document;
        let r = bcl.u.profilig.loadData(), e = l.querySelectorAll(bcl.u.profilig.props.cssProfilingSelector), i = l.querySelector(bcl.u.profilig.props.bookButtonWizardBannerSelector), t = i?.dataset.hotel;
        r && r.profiled && t && t === r.preferences.hotelId ? bcl.u.forEach(e, bcl.u.profilig.showProfilingTag) : bcl.u.forEach(e, bcl.u.profilig.hideProfilingTag);
    },
    loadData: function() {
        let o = localStorage.getItem(bcl.u.profilig.props.localStorageProp);
        return null != o && JSON.parse(o);
    },
    showProfilingTag: function(o) {
        let l = bcl.u.closest(o, "." + bcl.u.profilig.props.cssCarrouselListSelector);
        (null == l || l && 'true' === l.dataset.profiled) && bcl.u.removeClass(o, bcl.u.profilig.props.hidden);
    },
    hideProfilingTag: function(o) {
        bcl.u.addClass(o, bcl.u.profilig.props.hidden);
    }
}, bcl.u.docReady(bcl.u.profilig.init);
