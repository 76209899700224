bcl.c.genericHighlights = {
    props: {
        selector: ".c-generic-highlights",
        containerSelector: ".c-generic-highlights__container",
        itemSelector: ".c-generic-highlights__item",
        hidden: "hidden",
        active: "mod--active",
        noVisible: "mod--no-visible",
        viewMoreButtonSelector: ".view_more",
        viewLessButtonSelector: ".view_less"
    },
    init: function(e) {
        var i = (e || document).querySelectorAll(bcl.c.genericHighlights.props.selector);
        bcl.u.forEach(i, bcl.c.genericHighlights.loadData);
    },
    loadData: function(e) {
        let i = e.querySelectorAll(bcl.c.genericHighlights.props.containerSelector + " " + bcl.c.genericHighlights.props.itemSelector), c = e.querySelector(bcl.c.genericHighlights.props.viewMoreButtonSelector), l = e.querySelector(bcl.c.genericHighlights.props.viewLessButtonSelector), t = e.querySelector(bcl.c.genericHighlights.props.containerSelector), s = parseInt(e.dataset.mobileitems ? e.dataset.mobileitems : "3"), o = parseInt(e.dataset.mobileitems ? e.dataset.desktopitems : "6"), r = parseInt(e.dataset.numberItemsList), n = bcl.u.mobile.isMobile() || bcl.u.mobile.isTablet() ? s : o, g = bcl.c.genericHighlights.loadNodes(i, n);
        bcl.c.genericHighlights.hideInitial(c, i, n, g), c && (r <= n && (bcl.u.addClass(c, 'hidden'), bcl.u.addClass(t, bcl.c.genericHighlights.props.active)), c.addEventListener('click', function() {
            bcl.c.genericHighlights.loadMore(c, l, g, t);
        })), l && l.addEventListener('click', function() {
            bcl.c.genericHighlights.loadLess(l, c, g, t);
        });
    },
    hideInitial: function(e, i, c, l) {
        bcl.u.forEach(l, bcl.c.genericHighlights.hideItem), i.length > c && bcl.u.removeClass(e, bcl.c.genericHighlights.props.noVisible);
    },
    loadMore: function(e, i, c, l) {
        bcl.u.forEach(c, bcl.c.genericHighlights.showItem), bcl.u.removeClass(i, bcl.c.genericHighlights.props.noVisible), bcl.u.addClass(e, bcl.c.genericHighlights.props.noVisible), bcl.u.addClass(l, bcl.c.genericHighlights.props.active);
    },
    loadLess: function(e, i, c, l) {
        bcl.u.forEach(c, bcl.c.genericHighlights.hideItem), bcl.u.removeClass(i, bcl.c.genericHighlights.props.noVisible), bcl.u.addClass(e, bcl.c.genericHighlights.props.noVisible), bcl.u.removeClass(l, bcl.c.genericHighlights.props.active);
    },
    showItem: function(e) {
        bcl.u.removeClass(e, bcl.c.genericHighlights.props.noVisible);
    },
    hideItem: function(e) {
        bcl.u.addClass(e, bcl.c.genericHighlights.props.noVisible);
    },
    loadNodes: function(e, i) {
        var c = [];
        return bcl.u.forEach(e, function(e, l) {
            l > i - 1 && c.push(e);
        }), c;
    }
}, bcl.u.docReady(bcl.c.genericHighlights.init);
