import { fastbookingSelector as e, parentSelector as t } from 'barcelo-components/structure/header-detail/js/c-header-detail';
bcl.c.wizardResort = {
    props: {
        wizardParentClass: ".wizard-JS",
        stepClass: ".step-JS",
        nextStepButtonClass: ".next-step-JS",
        prevStepButtonClass: ".prev-step-JS",
        categoryServiceListClass: ".service-list-JS",
        categoryServiceLiItemSelector: ".categories-check__item-JS",
        firstStepIntoStep3Class: ".first-step-item-JS",
        secondStepIntoStep3Class: ".second-step-items-JS",
        progressNodeClass: ".circular-progress-JS",
        progressImageContainer: ".image-containter-JS",
        hotelTitleFindedClass: ".hotel-title-JS",
        wizarInitButtonClass: ".wizardModal-JS",
        lastButtonGoHotelClass: ".last-button-JS",
        linearProgressClass: ".linear-progress-JS",
        linearProgressBarClass: ".linear-progress-bar-JS",
        iconsProgressClass: ".icon-progress-JS",
        listIconsContainer: ".list-icon-JS",
        iconFinishProgressClass: ".finish-icon-container-JS",
        wizardBannerSelector: ".banner-wizard-resort-JS",
        wizardButtonSelector: ".wizardModal-JS",
        wizardButtonBookSelector: ".booking-button-JS",
        wizardBannerTitleSelector: ".wizard-banner-title-JS",
        wizardBannerPerfiledTitleSelector: ".wizard-banner-perfiled-title-JS",
        wizardBannerSubtitleSelector: ".wizard-banner-subtitle-JS",
        wizardBannerPerfiledSubtitleSelector: ".wizard-banner-perfiled-subtitle-JS",
        wizardBannerPerfiledButtonOpenModalClass: "wizard-modal-profiled",
        $wizard: null,
        steps: [
            {
                step: 0,
                $item: null,
                active: !1,
                complete: !1
            },
            {
                step: 1,
                $item: null,
                active: !1,
                complete: !1,
                item: null
            },
            {
                step: 2,
                $item: null,
                active: !1,
                complete: !1,
                items: [],
                editMode: !1
            },
            {
                step: 3,
                $item: null,
                active: !1,
                complete: !1
            },
            {
                step: 4,
                $item: null,
                active: !1,
                complete: !1
            },
            {
                step: 5,
                $item: null,
                active: !1,
                complete: !1
            }
        ],
        animation: {
            progressValue: 0,
            progressEndValue: 100,
            speed: 50
        },
        linearAnimation: {
            progressValueStart: 0,
            progressValueEnd: 100,
            speed: 50
        },
        candidatesToHotelProfile: [],
        bestHotel: {
            hotelObject: {},
            numberMatchesCategories: 0,
            numberMatchesCollection: 0
        },
        data: {
            profiled: !1,
            preferences: {
                categories: [],
                collection: null,
                hotelId: null
            }
        },
        profiled: !1
    },
    init: function(r) {
        let s = r || document;
        bcl.c.wizardResort.props.$wizard = s.querySelector(bcl.c.wizardResort.props.wizardParentClass), bcl.c.wizardResort.props.$wizard && bcl.c.wizardResort.initSteps(), bcl.c.wizardResort.initWizardBanner();
        let o = s.querySelector("." + e), l = o?.closest(t);
        l && (bcl.c.fastbooking.commonProps.fastbooking.parentFb = l);
        let c = localStorage.getItem('resortProfiled');
        bcl.c.wizardResort.props.profiled = !!c, c && bcl.c.wizardResort.props.$wizard ? bcl.c.wizardResort.goToModifyStep(c) : bcl.c.wizardResort.populeStep0(), c && bcl.c.wizardResort.changeWhenProfiled();
    },
    initSteps: function() {
        let e = null;
        if ((e = bcl.c.wizardResort.props.$wizard.querySelectorAll(bcl.c.wizardResort.props.stepClass)) && e.length == bcl.c.wizardResort.props.steps.length) for(let t = 0; t < e.length; t++)bcl.c.wizardResort.props.steps[t].$item = e[t], bcl.u.addClass(bcl.c.wizardResort.props.steps[t].$item, 'hidden');
    },
    populeStep0: function() {
        let e = bcl.c.wizardResort.props.steps[0].$item;
        bcl.u.removeClass(e, 'hidden'), bcl.c.wizardResort.props.steps[0].active = !0;
        let t = e?.querySelector(bcl.c.wizardResort.props.nextStepButtonClass);
        t && t.addEventListener('click', function() {
            bcl.c.wizardResort.props.steps[0].complete = !0, bcl.u.addClass(bcl.c.wizardResort.props.steps[0].$item, 'hidden'), bcl.c.wizardResort.props.steps[0].active = !1, bcl.c.wizardResort.populeStep1();
        });
    },
    populeStep1: function() {
        let e = bcl.c.wizardResort.props.steps[1].$item;
        e && bcl.u.removeClass(e, 'hidden'), bcl.c.wizardResort.props.steps[1].active = !0;
        let t = e.querySelectorAll(bcl.c.wizardResort.props.nextStepButtonClass);
        t && bcl.u.forEach(t, function(e) {
            e.addEventListener('click', function(e) {
                bcl.c.wizardResort.props.steps[1].complete = !0, bcl.u.addClass(bcl.c.wizardResort.props.steps[1].$item, 'hidden'), bcl.c.wizardResort.props.steps[1].active = !1;
                let t = e.target.dataset.collection, r = e.target.dataset.colletionTitle;
                bcl.c.wizardResort.props.steps[1].item = {
                    value: t,
                    title: r
                }, bcl.c.wizardResort.populeStep2();
            });
        });
    },
    checkArrows: function(e) {
        bcl.c.wizardResort.props.steps[2].items.length > 0 && bcl.u.removeClass(e, "mod--disabled"), bcl.c.wizardResort.props.steps[2].items.length <= 0 && bcl.u.addClass(e, "mod--disabled");
    },
    initCheckEvents: function(e) {
        let t = bcl.c.wizardResort.props.steps[2].$item.querySelector(bcl.c.wizardResort.props.nextStepButtonClass), r = bcl.u.closest(e.target, bcl.c.wizardResort.props.categoryServiceLiItemSelector);
        if (e.target.checked) bcl.c.wizardResort.props.steps[2].items.push(e.target.value), bcl.u.toggleClass(r, "mod--active");
        else {
            var s = bcl.c.wizardResort.props.steps[2].items.indexOf(e.target.value);
            -1 !== s && bcl.c.wizardResort.props.steps[2].items.splice(s, 1), bcl.u.toggleClass(r, "mod--active");
        }
        bcl.c.wizardResort.checkArrows(t);
    },
    populeStep2: function() {
        let e = bcl.c.wizardResort.props.steps[2].$item;
        e && bcl.u.removeClass(e, 'hidden'), bcl.c.wizardResort.props.steps[2].active = !0;
        let t = e.querySelector(bcl.c.wizardResort.props.nextStepButtonClass), r = e.querySelector(bcl.c.wizardResort.props.prevStepButtonClass);
        t.addEventListener('click', function() {
            0 !== bcl.c.wizardResort.props.steps[2].items.length && (bcl.c.wizardResort.props.steps[2].complete = !0, bcl.u.addClass(bcl.c.wizardResort.props.steps[2].$item, 'hidden'), bcl.c.wizardResort.props.steps[2].active = !1, bcl.c.wizardResort.populeStep3());
        }), r.addEventListener('click', function() {
            bcl.u.addClass(bcl.c.wizardResort.props.steps[2].$item, 'hidden'), bcl.c.wizardResort.props.steps[2].active = !1, bcl.c.wizardResort.populeStep1();
        });
        let s = e.querySelectorAll(bcl.c.wizardResort.props.categoryServiceListClass);
        bcl.u.forEach(s, function(e) {
            e.removeEventListener('change', bcl.c.wizardResort.initCheckEvents), e.addEventListener('change', bcl.c.wizardResort.initCheckEvents);
        }), bcl.c.wizardResort.props.steps[2].editMode = !0;
    },
    allStepsCompleted: function() {
        return bcl.c.wizardResort.props.steps[0].complete && bcl.c.wizardResort.props.steps[1].complete && bcl.c.wizardResort.props.steps[2].complete || bcl.c.wizardResort.props.profiled;
    },
    populeStep3: function() {
        let e = bcl.c.wizardResort.props.steps[3].$item;
        e && bcl.u.removeClass(e, 'hidden'), bcl.c.wizardResort.props.steps[3].active = !0, bcl.c.wizardResort.populeFirstStepContentToThirdStep(), bcl.c.wizardResort.populeSecondStepContentToThirdStep();
        let t = e.querySelector(bcl.c.wizardResort.props.nextStepButtonClass);
        t && t.addEventListener('click', function() {
            bcl.c.wizardResort.props.steps[3].complete = !0, bcl.c.wizardResort.allStepsCompleted() && (bcl.u.addClass(bcl.c.wizardResort.props.steps[3].$item, 'hidden'), bcl.c.wizardResort.props.steps[3].active = !1, bcl.c.wizardResort.populeStep4());
        });
    },
    populeStep4: function() {
        let e = bcl.c.wizardResort.props.steps[4].$item;
        e && bcl.u.removeClass(e, 'hidden'), bcl.c.wizardResort.props.steps[4].active = !0, bcl.c.wizardResort.initCircularAnimation(e);
    },
    populeStep5: function() {
        let e = bcl.c.wizardResort.props.steps[5].$item, t = e.querySelector(bcl.c.wizardResort.props.hotelTitleFindedClass), r = e.querySelector(bcl.c.wizardResort.props.lastButtonGoHotelClass);
        bcl.c.wizardResort.props.bestHotel && (t.innerHTML = bcl.c.wizardResort.props.bestHotel.hotelObject.hotelName, r.addEventListener('click', bcl.c.wizardResort.lastButtonClick)), bcl.c.wizardResort.initLinearAnimation(r), e && bcl.u.removeClass(e, 'hidden'), bcl.c.wizardResort.props.steps[5].active = !0;
    },
    lastButtonClick: function() {
        location.href = bcl.c.wizardResort.props.bestHotel.hotelObject.hotelPath;
    },
    initLinearAnimation: function(e) {
        let t = bcl.c.wizardResort.props.steps[5].$item.querySelector(bcl.c.wizardResort.props.linearProgressBarClass);
        if (t) {
            let o = bcl.c.wizardResort.props.linearAnimation.progressValueStart;
            if (0 == o) {
                o = 1;
                var r = 1, s = setInterval(function() {
                    r >= 100 ? (clearInterval(s), o = 0, e.click()) : (r++, t.style.width = r + "%");
                }, bcl.c.wizardResort.props.linearAnimation.speed);
            }
        }
    },
    populeFirstStepContentToThirdStep: function() {
        let e = bcl.c.wizardResort.props.steps[3].$item.querySelector(bcl.c.wizardResort.props.firstStepIntoStep3Class), t = document.createElement('div'), r = document.createElement('button');
        e.innerHTML = '', t.textContent = bcl.c.wizardResort.props.steps[1].item.title, r.textContent = ' ', e.appendChild(t), e.appendChild(r), r.addEventListener('click', function() {
            bcl.c.wizardResort.props.steps[3].complete = !1, bcl.u.addClass(bcl.c.wizardResort.props.steps[3].$item, 'hidden'), bcl.c.wizardResort.props.steps[3].active = !1, bcl.c.wizardResort.populeStep1();
        });
    },
    populeSecondStepContentToThirdStep: function() {
        let e = bcl.c.wizardResort.props.steps[3].$item.querySelector(bcl.c.wizardResort.props.secondStepIntoStep3Class), t = document.createElement('button');
        e.innerHTML = '', bcl.u.forEach(bcl.c.wizardResort.props.steps[2].items, function(t) {
            let r = document.createElement('div');
            r.textContent = t, e.appendChild(r);
        }), t.textContent = ' ', e.appendChild(t), t.addEventListener('click', function() {
            bcl.c.wizardResort.props.steps[3].complete = !1, bcl.u.addClass(bcl.c.wizardResort.props.steps[3].$item, 'hidden'), bcl.c.wizardResort.props.steps[3].active = !1, bcl.c.wizardResort.populeStep2();
        });
    },
    initCircularAnimation: function(e) {
        let t = e.querySelector(bcl.c.wizardResort.props.progressNodeClass), r = e.querySelectorAll(bcl.c.wizardResort.props.iconsProgressClass), s = Math.round(bcl.c.wizardResort.props.animation.progressEndValue / r.length), o = 0;
        r && bcl.u.removeClass(r[o], 'hidden'), bcl.c.wizardResort.populeHotelData();
        let l = setInterval(()=>{
            if (bcl.c.wizardResort.props.animation.progressValue++, bcl.c.wizardResort.props.animation.progressValue % s == 0 && o < r.length && (bcl.u.addClass(r[o], 'hidden'), o++, bcl.u.removeClass(r[o], 'hidden')), t.style.background = `conic-gradient(
                #BEB296 ${3.6 * bcl.c.wizardResort.props.animation.progressValue}deg,
                #EFEDEE ${3.6 * bcl.c.wizardResort.props.animation.progressValue}deg
            )`, bcl.c.wizardResort.props.animation.progressValue === bcl.c.wizardResort.props.animation.progressEndValue) {
                clearInterval(l);
                let t = e.querySelector(bcl.c.wizardResort.props.iconFinishProgressClass), r = e.querySelector(bcl.c.wizardResort.props.listIconsContainer);
                t && bcl.u.removeClass(t, 'hidden'), r && bcl.u.addClass(r, 'hidden'), setTimeout(function() {
                    bcl.c.wizardResort.props.steps[4].complete = !0, bcl.u.addClass(bcl.c.wizardResort.props.steps[4].$item, 'hidden'), bcl.c.wizardResort.props.steps[4].active = !1, bcl.c.wizardResort.populeStep5();
                }, 2000);
            }
        }, bcl.c.wizardResort.props.animation.speed);
    },
    populeHotelData: function() {
        let e = bcl.c.wizardResort.props.$wizard.dataset.wizardResource.split('.html')[0] + ".bcldata.json", t = document.querySelector(bcl.c.wizardResort.props.wizarInitButtonClass).dataset.currentPage;
        e && t && bcl.ajax.postRequest({
            data: {
                currentPagePath: t
            },
            url: e,
            callback: bcl.c.wizardResort.normalizeHotelData
        });
    },
    normalizeHotelData: function(e) {
        let t = null;
        e.response && (t = JSON.parse(e.response)) && bcl.u.forEach(t, bcl.c.wizardResort.filterProfileHotel), bcl.c.wizardResort.selectBestHotel();
    },
    filterProfileHotel: function(e) {
        let t = 0, r = 0, s = e.collectionsName?.filter((e)=>e === bcl.c.wizardResort.props.steps[1].item.value);
        s && s.length > 0 && r++, bcl.u.forEach(bcl.c.wizardResort.props.steps[2].items, function(r) {
            let s = e.categoryServicesName?.filter((e)=>e === r);
            s && s.length > 0 && t++;
        }), bcl.c.wizardResort.props.candidatesToHotelProfile.push({
            matchesCategoryServices: t,
            matchesCollections: r,
            hotelInfo: e
        });
    },
    evaluateCandidates: function(e) {
        return 1 === e.matchesCollections && e.matchesCategoryServices >= bcl.c.wizardResort.props.bestHotel.numberMatchesCategories && bcl.c.wizardResort.props.bestHotel.hotelObject || !bcl.c.wizardResort.props.bestHotel.hotelObject || 0 === Object.keys(bcl.c.wizardResort.props.bestHotel.hotelObject).length;
    },
    selectBestHotel: function() {
        bcl.u.forEach(bcl.c.wizardResort.props.candidatesToHotelProfile, function(e) {
            bcl.c.wizardResort.evaluateCandidates(e) && (bcl.c.wizardResort.props.bestHotel.numberMatchesCollection = e.matchesCollections, bcl.c.wizardResort.props.bestHotel.numberMatchesCategories = e.matchesCategoryServices, bcl.c.wizardResort.props.bestHotel.hotelObject = e.hotelInfo, bcl.c.wizardResort.localstorageSetInfo());
        });
    },
    localstorageSetInfo: function() {
        bcl.c.wizardResort.props.data.profiled = !0, bcl.c.wizardResort.props.data.preferences.categories = bcl.c.wizardResort.props.steps[2].items, bcl.c.wizardResort.props.data.preferences.collection = bcl.c.wizardResort.props.steps[1].item, bcl.c.wizardResort.props.data.preferences.hotelId = bcl.c.wizardResort.props.bestHotel.hotelObject.hotelId, localStorage.setItem('resortProfiled', JSON.stringify(bcl.c.wizardResort.props.data));
    },
    goToModifyStep: function(e) {
        bcl.c.wizardResort.props.data = JSON.parse(e), bcl.c.wizardResort.props.steps[2].items = [
            ...bcl.c.wizardResort.props.data.preferences.categories
        ], bcl.c.wizardResort.props.steps[1].item = bcl.c.wizardResort.props.data.preferences.collection;
        let t = bcl.c.wizardResort.props.steps[2].$item.querySelectorAll(bcl.c.wizardResort.props.categoryServiceListClass), r = bcl.c.wizardResort.props.steps[2].$item.querySelector(bcl.c.wizardResort.props.nextStepButtonClass);
        bcl.u.forEach(t, function(e) {
            let t = bcl.u.closest(e, bcl.c.wizardResort.props.categoryServiceLiItemSelector);
            1 === bcl.c.wizardResort.props.steps[2].items.filter((t)=>t === e.value).length && (e.checked = !0, bcl.u.toggleClass(t, "mod--active"));
        }), bcl.c.wizardResort.checkArrows(r), bcl.c.wizardResort.populeStep3();
    },
    changeWhenProfiled: function() {
        let e = document.querySelector(bcl.c.wizardResort.props.wizardBannerSelector);
        if (bcl.c.wizardResort.props.profiled && e) {
            let t = e.querySelector(bcl.c.wizardResort.props.wizardButtonSelector), r = t.dataset.perfiledText;
            if (t && r) {
                bcl.u.addClass(t, bcl.c.wizardResort.props.wizardBannerPerfiledButtonOpenModalClass);
                let e = t.querySelector('span');
                e && (e.textContent = r);
            }
            let s = e.querySelector(bcl.c.wizardResort.props.wizardBannerTitleSelector), o = e.querySelector(bcl.c.wizardResort.props.wizardBannerPerfiledTitleSelector), l = e.querySelector(bcl.c.wizardResort.props.wizardBannerSubtitleSelector), c = e.querySelector(bcl.c.wizardResort.props.wizardBannerPerfiledSubtitleSelector);
            s && o && (bcl.u.addClass(s, "hidden"), bcl.u.removeClass(o, "hidden")), l && c && (bcl.u.addClass(l, "hidden"), bcl.u.removeClass(c, "hidden"));
            let i = e.querySelector(bcl.c.wizardResort.props.wizardButtonBookSelector);
            i && i?.dataset.hotel && bcl.u.removeClass(i, "hidden");
        }
    },
    initWizardBanner: function() {
        window.addEventListener('scroll', bcl.c.wizardResort.showWizardBanner);
    },
    showWizardBanner: function() {
        let e = document.querySelector(bcl.c.wizardResort.props.wizardBannerSelector);
        if (e) {
            var t = window.innerHeight, r = bcl.s.scrollUp.props.$footer && bcl.s.scrollUp.props.$footer.getBoundingClientRect(), s = e.dataset.screens;
            s || (s = bcl.s.scrollUp.props.numberOfScreens);
            var o = t * s, l = !1;
            if ((window.scrollY || document.documentElement.scrollTop) > o) {
                if (l = !0, bcl.u.removeClass(e, "hidden"), e.dataset.limitfooter && r && r.top < r.height) {
                    var c = e.dataset.limitfooter;
                    bcl.u.mobile.isMobile() && e.dataset.limitfootermobile && (c = e.dataset.limitfootermobile), e.style.top = r.top - parseInt(c) + "px", bcl.u.addClass(e, "hidden");
                } else e.style.top = "", bcl.u.removeClass(e, "hidden");
            }
            l || bcl.u.addClass(e, "hidden");
        }
    }
}, bcl.u.docReady(bcl.c.wizardResort.init);
