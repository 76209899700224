bcl.c.carouselResort = {
    props: {
        cssSelector: "cmp-resort-carousel",
        cssColorSectionSelector: "swiper-slide-card-color-JS",
        cssSelectorSwiper: ".swiper-wrapper[data-items-load]",
        nameAttr: "data-items-load",
        defaultColor: "#eedfbb",
        classToAdd: "filter__sand-100",
        config: {
            slidesPerView: 1,
            grabCursor: !0,
            watchOverflow: !0,
            observer: !0,
            observeParents: !0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
                clickable: !0
            }
        }
    },
    init: function(e) {
        let r = e || document, o = r.querySelectorAll('.' + bcl.c.carouselResort.props.cssSelector);
        o && bcl.u.swiper.init(r, bcl.c.carouselResort.props), o && bcl.u.forEach(o, bcl.c.carouselResort.initResortCarousel);
    },
    initResortCarousel: function(e) {
        bcl.c.carouselResort.togglePrevClass(e), bcl.c.carouselResort.initChageColors(e);
    },
    initChageColors: function(e) {
        let r = e.querySelectorAll('.' + bcl.c.carouselResort.props.cssColorSectionSelector);
        r && bcl.u.forEach(r, bcl.c.carouselResort.colorTest);
    },
    togglePrevClass: function(e) {
        let r = e.querySelector(".swiper-slide");
        e.swiper.on('slideChangeTransitionEnd', ()=>{
            e.classList.toggle("mod--slide-prev", r.classList.contains("swiper-slide-active"));
        });
    },
    colorTest: function(e) {
        let r = e.style.backgroundColor;
        '' == r && (r = bcl.c.carouselResort.props.defaultColor);
        let o = bcl.c.carouselResort.convertToHex(r);
        o && bcl.u.addClass(e, o);
    },
    convertToHex: (e = "")=>{
        let r = e && e.includes("rgb(") && !e.includes("#") ? e.replace("rgb(", "").replace(")", "").split(",") : null, { r: o, g: s, b: l } = r ? {} : bcl.c.carouselResort.hexToRgb(e);
        return r ? bcl.c.carouselResort.darkOrLight(parseInt(r[0]), parseInt(r[1]), parseInt(r[2])) : bcl.c.carouselResort.darkOrLight(o, s, l);
    },
    darkOrLight: function(e, r, o) {
        return (299 * e + 587 * r + 114 * o) / 255000 >= 0.5 ? null : bcl.c.carouselResort.props.classToAdd;
    },
    hexToRgb: (e)=>{
        let r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e);
        return r ? {
            r: parseInt(r[1], 16),
            g: parseInt(r[2], 16),
            b: parseInt(r[3], 16)
        } : null;
    }
}, bcl.u.docReady(bcl.c.carouselResort.init);
